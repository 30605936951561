import { render, staticRenderFns } from "./indexM_two.vue?vue&type=template&id=5f37578c&scoped=true&"
import script from "./indexM_two.vue?vue&type=script&lang=js&"
export * from "./indexM_two.vue?vue&type=script&lang=js&"
import style0 from "./assets/indexM_two.rem.less?vue&type=style&index=0&id=5f37578c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f37578c",
  null
  
)

export default component.exports