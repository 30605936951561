<template>
  <div class="page flex-col" style="height: 50.334rem !important;">
    <div class="block_7 flex-col ">
      <div class="block_8 flex-row top">
        <img class="label_1" referrerpolicy="no-referrer" src="./assets/img/logo.png" />
        <div class="text-wrapper_21 flex-col justify-between">
          <span class="text_1 textcolor">福建超数科技有限公司</span>
          <span class="text_2 textcolor">&nbsp;—中国信通院《可信虚拟人白皮书》参编单位</span>
        </div>
        <div v-if="!$store.state.company_name" class="group_1 flex-col cursorp-p" @click="login">
          <span class="text_3">登录</span>
        </div>
        <div v-if="$store.state.company_name"></div>
      </div>
      <div style="height: 3.917rem;"></div>
      <div class="block_9 flex-row">
        <div class="text-group_6 flex-col justify-between">
          <span class="paragraph_1">基于大模型AI数字人应用</span>
          <span class="text_7">—企业级TV版</span>
        </div>
      </div>
    </div>
    <video poster="./assets/img/staticM.png" controls id="video" preload="auto" crossorigin="anonymous"
      class="bloom-video image_1" src="./assets/mp4/staticM.mp4"></video>
    <div class="block_10 flex-col">
      <div v-if="!$store.state.company_name" class="button text-wrapper_3 flex-col justify-center align-center cursorp-p"
        @click="register">
        <span class="colorF">免费试用申请</span>
      </div>
      <div v-else style="display: flex;">
        <div class="text-wrapper_3 button flex-col justify-center align-center cursorp-p" @click="downloadApp">
          <span class="colorF">下载app体验</span>
        </div>
        <div class="text-wrapper_3 button flex-col justify-center align-center cursorp-p" @click="ToThird">
          <span class="colorF">购买终身服务</span>
        </div>

      </div>
      <span class="text_6">专为企业提供一站式服务，打造能说、会动、可交互的AI虚拟数字人，让企业走在元宇宙时代的前沿，让我们掌控未来，开启智能互动时代！</span>
    </div>
  </div>
</template>
<script>
// import { MessageBox } from 'element-ui';
// import { Dialog } from 'vant';

import { Notify } from 'vant';
export default {
  name: "One",
  data() {
    return {
      constants: {},
      animateStatus: false,
      isVisible: false,
      text7IsVisible: false,
      M: false
    };
  },
  created() {
  },
  mounted() {
    console.log("asdfasd")
    let video = document.querySelector("#video")

    video.addEventListener("ended", () => {
      console.log("3432423423423423423423")
      if (!this.$store.state.company_name) {
        console.log("3432423423423423423423")
        this.videoEnded()

      } else {
        this.videoDownload()
      }
    })
  },
  methods: {
    handleVisible(isVisible) {
      this.isVisible = isVisible;
    },
    handleText7Visible(isVisible) {
      this.isVisible = isVisible;
    },
    register() {
      this.$router.push("/signup")
    },
    downloadApp() {
      window.open("https://public.fjcs.xyz/%E5%85%83%E5%8F%AF%E5%8F%AF.apk")

      setTimeout(() => {
        Notify({ type: 'success', message: '已为您下载' });
      })
    },
    skip() {
      window.open("http://www.keledigit.cn/")
    },
    login() {
      this.$router.push("/login")
    },
    videoEnded() {
      // Dialog.confirm({
      //   message: '点击立即试用申请',
      //   theme: 'round-button',
      //   closeOnPopstate: false,
      //   closeOnClickOverlay: true
      // }).then(() => {
      //   console.log("test")
      //   this.register()
      // });
      let isconfirm = confirm("立即试用申请")
      if (isconfirm) {
        this.register()
      }
      // MessageBox.confirm("点击立即试用申请", {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   // type: 'warning',
      //   center: true,
      //   roundButton: true,
      // }).then(() => {
      //   this.register()
      // }).catch(() => {
      // });
    },
    videoDownload() {
      let isconfirm = confirm("立即下载使用")
      if (isconfirm) {
        this.downloadApp()
      }
    },

    ToThird() {
      window.open("https://m.tb.cn/h.5fb0FpX?tk=0YpKdAk7KBE")
    },
  },
};
</script>
<style scoped lang="less" src="./assets/indexM_one.rem.less" />


<style scoped lang="less">
.colorF {
  color: #fff !important;
}

.top {
  height: 50px;
  position: fixed;
  background-color: #151313;
  margin: 0 !important;
  width: 29.6rem !important;
  z-index: 999;
  justify-content: space-around;

  .group_1 {
    width: 3rem;
  }
}

.txtCenter {
  width: 100% !important;
  text-align: center !important;
  margin: 0 !important;
}

.bloom-video {
  width: 30rem !important;
  left: 0 !important;
  top: 0 !important;
  // padding: 20px;
  margin: 0 !important;
}

.bloom-video::poster {
  object-fit: cover;
  /* 设置封面图片填充方式 */
  /* 可以添加其他样式，如边框、阴影等 */
}

.textcolor {
  color: #5573a3 !important;
}

.button {
  margin: 0 auto !important;
  margin-top: 1.084rem !important;
}
</style>