<template>
  <div>
    <one></one>
    <two></two>
  </div>
</template>
<script>
import One from "./indexM_one.vue"
import Two from "./indexM_two.vue"
export default {
  components: {
    Two,
    One
  },
  data() {
    return {
      constants: {},
      animateStatus: false,
      isVisible: false,
      text7IsVisible: false,
      M: false
    };
  },
  created() {
    (function flexible(window, document) {
      function resetFontSize() {
        console.log("dsf")
        // const size = (document.documentElement.clientWidth / 360) * 12;
        // document.documentElement.style.fontSize = size + 'px';
        let size;
        if (window.matchMedia("(min-width: 768px)").matches) {
          // PC端
          size = (document.documentElement.clientWidth / 1385) * 12;
        } else {
          // 手机端
          size = (document.documentElement.clientWidth / 360) * 12;
        }
        document.documentElement.style.fontSize = size + 'px';
      }

      // reset root font size on page show or resize
      window.addEventListener('pageshow', resetFontSize);
      window.addEventListener('resize', resetFontSize);
    })(window, document);

  },
  methods: {
    handleVisible(isVisible) {
      this.isVisible = isVisible;
    },
    handleText7Visible(isVisible) {
      this.isVisible = isVisible;
    },
    register() {
      this.$router.push("/signup")
    },
    downloadApp() {
      window.open("https://public.fjcs.xyz/%E5%85%83%E5%8F%AF%E5%8F%AF.apk")
    },
    skip() {
      window.open("http://www.keledigit.cn/")
    },
    login() {
      this.$router.push("/login")
    }
  },
};
</script>


<style>

</style>